<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="uploading">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                <b-form  @submit.prevent="handleSubmit(register)" >
                    <b-row>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="organization"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro.organization') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="pumpInformation.org_id"
                            :options="organizationList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="division"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="pumpInformation.division_id"
                            :options="divisionList"
                            id="division_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="district"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="pumpInformation.district_id"
                            :options="districtList"
                            id="district_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="upazila"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="pumpInformation.upazilla_id"
                            :options="upazilaList"
                            id="upazila_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Union" vid="union_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="union_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('org_pro_union.union') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                            plain
                            v-model="pumpInformation.union_id"
                            :options="unionList"
                            id="union_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Mouza" vid="mouza_id" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="3"
                                label-for="mouza_id"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                {{ $t('irrigation_config.mouza') }}
                                </template>
                                <b-form-select
                                plain
                                v-model="pumpInformation.mouza_id"
                                :options="mouzaList"
                                id="mouza_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    <!-- <b-col lg="6" sm="12">
                        <ValidationProvider name="Pump Id" vid="pump_id" rules="required|min_value:1">
                            <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="pump_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('irriPumpInformation.pump_id') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="pumpInformation.pump_id"
                                :options="pumpInfoList"
                                id="pump_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0">
                                    {{ $t('globalTrans.select') }}
                                </b-form-select-option>
                                </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col> -->
                    <b-col lg="6" sm="12">
                        <ValidationProvider name="Attachment file" vid="excel_data" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="excel_data"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('globalTrans.excelFile')}}<span class="text-danger">*</span>
                            </template>
                            <b-form-file
                            id="excel_data"
                            v-model="excel_attachment"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            v-on:change="onFileChange"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-file>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>
                    </b-row>
                    <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                    </div>
                    </div>
                </b-form>
                </ValidationObserver>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpOperatorExcelUpload } from '../../api/routes'
import { mapGetters } from 'vuex'

export default {
  name: 'FormLayout',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      uploading: false,
      pumpInformation: {
        org_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        mouza_id: 0,
        attachment: ''
        // pump_id: 0
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      excel_attachment: [],
      attachment: '',
      pumpInfoList: [],
      mouzaList: []
    }
  },
  created () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.pumpInformation.org_id = this.authUser.org_id
    } else if (this.authUser.role_id === 0) {
      this.pumpInformation.org_id = this.authUser.office_detail.org_id
      this.pumpInformation.division_id = this.authUser.office_detail.division_id
      this.pumpInformation.district_id = this.authUser.office_detail.district_id
      this.pumpInformation.upazilla_id = this.authUser.office_detail.upazilla_id
      this.pumpInformation.union_id = this.authUser.office_detail.union_id
    }
    if (this.id) {
      const tmp = this.getpumpInformationData()
      this.pumpInformation = tmp
    }
  },
  mounted () {
    core.index()
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    organizationList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'pumpInformation.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'pumpInformation.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'pumpInformation.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'pumpInformation.union_id': function (newVal, oldVal) {
      this.mouzaList = this.getMouzaList(newVal)
      this.getPumpInformationList(newVal)
    },
    'pumpInformation.mouza_id': function (newVal, oldVal) {
      this.getPumpInformationList(this.pumpInformation.union_id, newVal)
    }
  },
  methods: {
    onFileChange (e) {
      this.attachment = e.target.files[0]
    },
    getpumpInformationData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.uploading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      var formData = new FormData()

      formData.append('excel_file', this.attachment)
      formData.append('org_id', this.pumpInformation.org_id)
      formData.append('division_id', this.pumpInformation.division_id)
      formData.append('district_id', this.pumpInformation.district_id)
      formData.append('upazilla_id', this.pumpInformation.upazilla_id)
      formData.append('union_id', this.pumpInformation.union_id)
    //   formData.append('pump_id', this.pumpInformation.pump_id)

      result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpOperatorExcelUpload, formData, config)

      loadinState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadinState)
      this.uploading = false

      if (result.success) {
        this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: result.message,
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-6')
      } else {
        this.$refs.form.setErrors(result.errors)
        this.$toast.error({
          title: 'Error',
          message: 'Operation failed! Please, try again.'
        })
      }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }

      return unionList
    },
    getMouzaList (unionId = null) {
      const mouzaList = this.$store.state.IrriConfig.commonObj.mouzaList.filter(item => item.status === 0)
      if (unionId) {
        return mouzaList.filter(union => union.union_id === unionId)
      }
      return mouzaList
    },
    getPumpInformationList (unionId, mouzaId = 0) {
      const pumpInfos = this.$store.state.IrriConfig.commonObj.pumpInfoList.filter(pump => {
        if (mouzaId) {
          return pump.union_id === unionId && pump.mouza_id === mouzaId && pump.status === 0
        }
        return pump.union_id === unionId && pump.status === 0
      })
      this.pumpInfoList = pumpInfos.map((obj, index) => {
        if (this.$i18n.locale === 'bn') {
          return { value: obj.id, text: obj.pump_id }
        } else {
          return { value: obj.id, text: obj.pump_id }
        }
      })
    }
  }
}
</script>
