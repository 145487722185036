import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
// import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (reportTitle, thisObject, data) => {
  try {
    Store.commit('mutateCommonProperties', {
      loading: true
    })
    if (i18n.locale === 'bn') {
      pdfMake.vfs = pdfFontsBn.pdfMake.vfs
    } else {
      pdfMake.vfs = pdfFontsEn.pdfMake.vfs
    }
    // const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
    const pdfContent = [
      { text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' }
    ]

    const allRowsHead = [
        [
        { text: thisObject.$t('org_pro.organization'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.org_name : data.org_name_bn, alignment: 'left', style: 'search' },
        { text: thisObject.$t('externalUserIrrigation.nid'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: thisObject.$n(data.nid, { useGrouping: false }), alignment: 'left', style: 'search' }
      ]
    ]

    allRowsHead.push([
      { text: thisObject.$t('irriPumpInformation.pump_id'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      // { text: i18n.locale === 'en' ? data.division_name : data.division_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$n(data.pump_id, { useGrouping: false }), alignment: 'left', style: 'search' },
      { text: thisObject.$t('externalUserIrrigation.village'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.village_name : data.village_name_bn, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('user.namel'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.name : data.name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('user.phone_no'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.mobile_no, { useGrouping: false }), alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('externalUserIrrigation.father_name'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.father_name : data.father_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.email'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.email : data.email, alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('externalUserIrrigation.mother_name'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.mother_name : data.mother_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.latitude'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.latitude, { useGrouping: false }), alignment: 'left', style: 'search' }
    ])

    allRowsHead.push([
      { text: thisObject.$t('pusti_mapping.gender'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: i18n.locale === 'en' ? data.gender_name : data.gender_name_bn, alignment: 'left', style: 'search' },
      { text: thisObject.$t('irriPumpInformation.longitude'), alignment: 'right', style: 'search' },
      { text: ':', style: 'search', alignment: 'center' },
      { text: thisObject.$n(data.longitude, { useGrouping: false }), alignment: 'left', style: 'search' }
    ])

    if (data.gender === 2) {
      allRowsHead.push([
        { text: thisObject.$t('irriPumpInformation.husband_name'), alignment: 'right', style: 'search' },
        { text: ':', style: 'search', alignment: 'center' },
        { text: i18n.locale === 'en' ? data.husband_name : data.husband_name, alignment: 'left', style: 'search' },
        {},
        {},
        {}
      ])
    }

      pdfContent.push({
        table: {
          headerRows: 0,
          widths: ['24%', '2%', '24%', '24%', '2%', '24%'],
          body: allRowsHead
        },
        layout: {
          hLineWidth: function (i, node) {
            return 0
          },
          vLineWidth: function (i, node) {
            return 0
          }
        }
      })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 10 : 10,
            margin: [3, 3, 3, 3],
            bold: true
          },
          td: {
            fontSize: (i18n === 'bn') ? 10 : 10,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n === 'bn') ? 10 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 7]
          },
          fertilizerSHeader: {
              fontSize: 10,
              margin: [40, 0, 0, 0]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 10]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 15, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
