<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                        <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Organization" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="org_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="pumpOperator.org_id"
                                            :options="getOrgList"
                                            id="org_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="!isEdit">
                                <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="division"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('org_pro_division.division') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="pumpOperator.division_id"
                                        :options="divisionList"
                                        id="division_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="!isEdit">
                                <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="district"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('org_pro_district.district') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="pumpOperator.district_id"
                                        :options="districtList"
                                        id="district_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="!isEdit">
                                <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="upazila"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('org_pro_upazilla.upazilla') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                            plain
                                            v-model="pumpOperator.upazilla_id"
                                            :options="upazilaList"
                                            id="upazila_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="!isEdit">
                                <ValidationProvider name="Union" vid="union_id" rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="union_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('org_pro_union.union') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="pumpOperator.union_id"
                                        :options="unionList"
                                        id="union_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="!isEdit">
                                <ValidationProvider name="Mouza" vid="mouza_id" rules="">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="mouza_id"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                        {{ $t('irrigation_config.mouza') }}
                                        </template>
                                        <b-form-select
                                        plain
                                        v-model="pumpOperator.mouza_id"
                                        :options="mouzaList"
                                        id="mouza_id"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        >
                                        <template v-slot:first>
                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                        </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Pump ID" vid='pump_id' rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        :label-cols-sm="id ? '5' : '4'"
                                        label-for="pump_id"
                                        slot-scope="{ valid, errors }"
                                    >
                                    <template v-slot:label>
                                        {{$t('irriPumpInformation.pump_id')}} <span class="text-danger">*</span>
                                    </template>
                                        <b-form-select v-if="!isEdit"
                                            plain
                                            v-model="pumpOperator.pump_id"
                                            :options="pumpList"
                                            id="pump_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <b-form-select v-if="isEdit"
                                            plain
                                            v-model="pumpOperator.pump_id"
                                            :options="pumpListEdit"
                                            id="pump_id"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            >
                                            <template v-slot:first>
                                                <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                            </template>
                                        </b-form-select>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Name (En)" vid='name' rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="name"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('user.name')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        id="name"
                                        v-model="pumpOperator.name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Name (Bn)" vid='name_bn'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        :label="$t('user.name_bn')"
                                        label-for="name_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                        id="name_bn"
                                        v-model="pumpOperator.name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Father Name (En)" vid='father_name'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label="$t('externalUserIrrigation.father_namef')"
                                        label-for="father_name"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                        id="father_name"
                                        v-model="pumpOperator.father_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Father Name (Bn)" vid='father_name_bn'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        :label="$t('externalUserIrrigation.father_name_bn')"
                                        label-for="father_name_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                        id="father_name_bn"
                                        v-model="pumpOperator.father_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Mother Name (En)" vid='mother_name'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label="$t('externalUserIrrigation.mother_namef')"
                                        label-for="mother_name"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                        id="mother_name"
                                        v-model="pumpOperator.mother_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Mother Name (Bn)" vid='mother_name_bn'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        :label="$t('externalUserIrrigation.mother_name_bn')"
                                        label-for="mother_name_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                        id="mother_name_bn"
                                        v-model="pumpOperator.mother_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Village Name (En)" vid='village_name'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label="$t('externalUserIrrigation.villagef')"
                                        label-for="village_name"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                        id="village_name"
                                        v-model="pumpOperator.village_name"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Village Name (Bn)" vid='village_name_bn'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        :label="$t('externalUserIrrigation.village_bn')"
                                        label-for="village_name_bn"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                        id="village_name_bn"
                                        v-model="pumpOperator.village_name_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                         <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Gender" vid='gender' rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="gender"
                                        slot-scope="{ valid, errors }"
                                        >
                                        <template v-slot:label>
                                            {{$t('pusti_mapping.gender')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-radio-group
                                            v-model="pumpOperator.gender"
                                            :options="genders"
                                            class="mb-3"
                                            value-field="item"
                                            text-field="name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-radio-group>
                                        <div class="invalid-feedback d-block">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12" v-if="pumpOperator.gender == 2">
                                <ValidationProvider name="Husband Name" vid='husband_name' rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        label-for="husband_name"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{ $t('irriPumpInformation.husband_name') }} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            id="husband_name"
                                            v-model="pumpOperator.husband_name"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="NID" vid='nid' rules="required">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        label-for="nid"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('externalUserIrrigation.nid')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                        type="number"
                                        id="nid"
                                        v-model="pumpOperator.nid"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Mobile" vid='mobile_no'  rules="required|min_value:1">
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        :label="$t('user.phone_no')"
                                        label-for="mobile_no"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <template v-slot:label>
                                            {{$t('irriPumpInformation.mobile')}} <span class="text-danger">*</span>
                                        </template>
                                        <b-form-input
                                            type="number"
                                            id="mobile_no"
                                            v-model="pumpOperator.mobile_no"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Email" vid='emil'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label="$t('irriPumpInformation.email')"
                                        label-for="emil"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                        id="emil"
                                        v-model="pumpOperator.email"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Latitude" vid='latitude'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        :label="$t('irriPumpInformation.latitude')"
                                        label-for="latitude"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                        id="latitude"
                                        v-model="pumpOperator.latitude"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Longitude" vid='longitude'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="4"
                                        :label="$t('irriPumpInformation.longitude')"
                                        label-for="longitude"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-input
                                        id="longitude"
                                        v-model="pumpOperator.longitude"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                        ></b-form-input>
                                        <div class="invalid-feedback">
                                        {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="12">
                                <ValidationProvider name="Longitude" vid='longitude'>
                                    <b-form-group
                                        class="row"
                                        label-cols-sm="5"
                                        :label="$t('irriPumpInformation.dailyTaskEntryRequired')"
                                        label-for="longitude"
                                        slot-scope="{ valid, errors }"
                                    >
                                        <b-form-checkbox
                                            v-model="pumpOperator.daily_task_entry_required"
                                            :state="errors[0] ? false : (valid ? true : null)"
                                            :value="1"
                                            :unchecked-value="0"
                                        >
                                        </b-form-checkbox>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                        </div>
                    </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl, authServiceBaseUrl } from '../../../../../config/api_config'
import { userStore, userDelete, pumpOperatorStore, pumpOperatorUpdate } from '../../api/routes'

export default {
  name: 'PumpOperatorForm',
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getPumpOperatorData()
      this.pumpOperator = tmp
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-information/list-allId', { org_id: this.pumpOperator.org_id }).then(response => {
        if (response.success) {
            const activeId = response.data.filter(item => item.status === 0)
            const pumpData = activeId.filter(pump => pump.id === this.pumpOperator.pump_id)
            this.pumpListEdit = pumpData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.pump_id }
                } else {
                return { value: obj.id, text: obj.pump_id }
                }
            })
        }
      })
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      isEdit: false,
      genders: [
        { item: 1, name: this.$t('pusti_mapping.male') },
        { item: 2, name: this.$t('pusti_mapping.female') }
      ],
      pumpOperator: {
        org_id: 0,
        pump_id: 0,
        division_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        mouza_id: 0,
        name: '',
        name_bn: '',
        father_name: '',
        father_name_bn: '',
        mother_name: '',
        mother_name_bn: '',
        nid: '',
        village_name: '',
        village_name_bn: '',
        mobile_no: '',
        email: '',
        latitude: '',
        longitude: '',
        pump_operator_user_id: 0,
        pump_operator_username: '',
        pump_operator_email: '',
        daily_task_entry_required: 0
      },
      districtList: [],
      upazilaList: [],
      unionList: [],
      pumpListEdit: [],
      mouzaList: [],
      pumpList: []
    }
  },
  computed: {
    getOrgList () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    }
  },
  watch: {
    'pumpOperator.division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'pumpOperator.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'pumpOperator.upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    },
    'pumpOperator.union_id': function (newVal, oldVal) {
        if (!this.id) {
            this.mouzaList = this.getMouzaList(newVal)
            this.pumpList = this.getPumpId(newVal)
        }
    },
    'pumpOperator.mouza_id': function (newVal, oldVal) {
        if (!this.id) {
            this.pumpList = this.getPumpId(this.pumpOperator.union_id, newVal)
        }
    }
  },
  methods: {
    getPumpOperatorData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      this.isEdit = true
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      try {
        let result = null
        let userResult = null
        const loadinState = { loading: false, listReload: false }

        if (this.pumpOperator.id) {
            result = await RestApi.putData(irriSchemeServiceBaseUrl, `${pumpOperatorUpdate}/${this.id}`, this.pumpOperator)
        } else {
            userResult = await RestApi.postData(authServiceBaseUrl, userStore, this.pumpOperator)
            if (!userResult.success) {
                this.$refs.form.setErrors(userResult.errors)
            }
            this.pumpOperator.pump_operator_user_id = userResult.data.id
            this.pumpOperator.pump_operator_username = userResult.data.username
            this.pumpOperator.pump_operator_email = userResult.data.email

            if (userResult) {
                result = await RestApi.postData(irriSchemeServiceBaseUrl, pumpOperatorStore, this.pumpOperator)

                if (!result.success) {
                    RestApi.deleteData(authServiceBaseUrl, `${userDelete}/${userResult.data.id}`)
                }
            }
        }

        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
            this.$toast.success({
                title: 'Success',
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
            })

            this.$bvModal.hide('modal-4')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
      } catch (error) {
         this.$refs.form.setErrors(error)
         this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    },
    getMouzaList (unionId = null) {
      const mouzaList = this.$store.state.IrriConfig.commonObj.mouzaList.filter(item => item.status === 0)
      if (unionId) {
        return mouzaList.filter(union => union.union_id === unionId)
      }
      return mouzaList
    },
    getPumpId (pumpId, mouzaId = 0) {
        const params = {
            org_id: this.pumpOperator.org_id,
            union_id: pumpId,
            mouza_id: mouzaId
        }
      RestApi.getData(irriSchemeServiceBaseUrl, 'pump-information/list-allId', params).then(response => {
        if (response.success) {
            const pumpData = response.data.filter(item => item.status === 0)
            this.pumpList = pumpData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.pump_id }
                } else {
                return { value: obj.id, text: obj.pump_id }
                }
            })
        }
      })
    }
  }
}
</script>
